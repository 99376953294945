.container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.moduleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.module {
  flex: 1 1 calc(33.333% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.moduleName {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.dayContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.dayContainerDisabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  opacity: 0.5;
}

.date {
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.avatar_container {
  cursor: pointer;
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.box_root{
  position: relative;
  background-color: var(--pro-secondary);
  padding: 12px;
  border-radius: 4px;
  &+.box_root{
      margin-top: 12px;
  }
  .btn_close{
      display: block;margin-left: auto;
      width: 5px;height: 5px;
  }
}
.inner_row{
position: relative;
padding-top: 7px;
padding-bottom: 7px;
white-space: nowrap;
min-height: 40px;
span{
  max-width: 500px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
&:first-child{
  margin-top: -6px;
}
&:last-child{
  margin-bottom: -6px;
}
& + .inner_row{
  &::after{
    position: absolute;
    left: -20px;
    top: 0;
    width: calc(100% + 40px);
    background-color: var(--pro-outline-border);
    height: 1px;
    content: '';
  }
}
br{
  display: none;
}
p{
  margin-bottom: 0;
}
}
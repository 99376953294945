.training_wrapper {
     table {
          width: 100%;

          tr {
               height: 25px;
               border-bottom: 1px solid rgb(223, 227, 235);

               td {
                    width: 60px;
                    padding:16px 12px;
                    &:first-child{
                         width: 14%;
                    }

                    // &:not(:last-child) {
                    //      border-right: 1px solid rgb(223, 227, 235);
                    // }
                    .date_time{
                         align-items: center;
                         .date{
                              flex: 0 0 20px;
                              align-items: center;
                              display: flex;
                              flex-direction: column;
                              text-align: left;
                              border-right: 1px solid var(--pro-outline-border);
                              padding-right:12px ;
                              margin-right: 10px;
                              span{
                                   font-size: 14px;
                                   font-weight: 600;
                                   line-height: 19.6px;
                              }
                              strong{
                                   font-size: 18px;
                                   font-weight: 700;
                                   line-height: 25.2px;
                              }
                         }
                         .time{
                              font-size: 13px;
                              font-weight: 500;
                              line-height: 18.2px;
                              color: #4C4C4C;
                         }
                    }
                    .title_desc{
                         .title{
                              font-size: 16px;
                              font-weight: 500;
                         }
                         .desc{
                              font-size: 14px;   
                              color: #4C4C4C;
                              margin: 0;
                         }
                         .title,.desc{
                              // white-space: nowrap;
                              // text-overflow: ellipsis;
                              // display: block;
                              // overflow: hidden;
                              // max-width: 500px;
                              // width: 100%;

                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;  
                              overflow: hidden;
                              // max-width: 800px;
                              // width: 100%;
                         }
                    }
                    .mentor{
                         display: flex;
                         align-items: center;
                         font-size: 13px;
                         font-weight: 500;
                         line-height: 22px;
                         span{
                              font-size: 22px !important;
                              font-weight: 300 !important;
                              display: inline-block;
                              margin-right: 7px;
                         }
                    }
               }
          }
     }
}
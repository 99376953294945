.root{
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    figure{
        img{
            max-width: 160px;
            
        }
    }

}



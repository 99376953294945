.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}

.table_popup {
  position: fixed;
  top: 70px;
  left: 50%;
  z-index: 2;
  // transform: translateX(-50%);
  background-color: #fff6db;
  border-radius: 3px;
  padding: 5px 10px;
  transform: scale(0.8) translateY(-10px);
  opacity: 0;
  transition: opacity 0.25s ease, transform 0.25s ease;
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.aling_btn {
  transform: rotate(90deg);
}


.main_container {
  width: 100%;
  // max-width: calc(100% - 385px);
  transition: 0.3s max-width ease-in-out;
  flex: 1;

   :global {
    .nav {
      @media (max-width : 1399px) {
        overflow: auto;
        overflow-y: hidden;
      }

      .nav-link {
        white-space: pre;
        bottom: -2px;
        
      }
    }

    .pro-pagination-wrapper {
      width: calc(100% - 385px);
    }
  }
}
.training_card{
  padding: 12px 16px;
  border: 1px solid var(--pro-outline-border);
  border-radius: 4px;
  flex: 0 0 32%;
  .date_time{
    flex: 0 0 130px;
    padding-right: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: center;
    p{
      font-size: 14px;
      font-weight: 600;
    }
    h6{
      font-size: 30px;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
    }
  }
  .inner_wrap{
    .title{
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      text-align: left;
      margin-bottom: 6px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .desc{
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .mentor{
      font-size: 13px;
      font-weight: 500;
      line-height: 18.2px;
      text-align: left;
    }
  }
}
.register_modal{
  :global{
    .modal-dialog{
      max-width: 600px;
      width: 100%;
    }
  }
}
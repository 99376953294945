.shimmer_container {
  height: 173px;
  .img{
    flex: 0 0 140px;
  }
  .button{
    width: 145px;
    height: 48px;
  }
  .tag{
    height: 30px;
    border-radius: 4px;
  }
    
  }
  
 
.root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--pro-secondary);
  justify-content: center;
  align-items: center;
  .root_inner {
    max-width: 423px;
    width: 60%;
  }
  .logoWrapper{
    width: 100%;
    max-width: 201.83px;
    margin-left: auto;
    margin-right: auto;
    img{
      object-fit: contain;
    }
  }
  
}


.auth{
  width: 100%;
  position: relative;
  :global{
    .pro-btn-link{
      margin: auto;
    }
  }
}
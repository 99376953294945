.root{
    position: relative;
    // border: 1px solid var(--pro-outline-border);
    // .graph{
    //     border-left: 1px solid var(--pro-outline-border);
    //     border-bottom: 1px solid var(--pro-outline-border);
    //     height:250px;
    //     width: 100%;
    //     align-items: end;
    //     .graph_item_wrap{
    //         height: 100%;
    //         align-items: end;
    //     }
    //     .graph_item{
    //         height: 65%;
    //         + .graph_item{
    //             height: 90%;
    //         }
    //     }
    // }
    .donut_chart {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        // background: conic-gradient(
        //     #7d7d7d 0% 25%,    /* Segment 1 */
        //     #9d9d9d 25% 50%,   /* Segment 2 */
        //     #bfbfbf 50% 75%,   /* Segment 3 */
        //     #dedede 75% 100%   /* Segment 4 */
        // );
        background: var(--pro-shimmer-bg);
        position: relative;
        overflow: hidden;
    }
    
    .donut_chart::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        height: 60%;
        background-color: #fff;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    
    .shimmer {
        position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    // background: conic-gradient(
    //     from 0deg,
    //     rgba(255, 255, 255, 0) 0%,
    //     rgba(255, 255, 255, 0.3) 50%,
    //     rgba(255, 255, 255, 0) 100%
    // );
    // background: var(--pro-shimmer-bg);
    background: conic-gradient(from 0deg, var(--pro-shimmer-gradient-color1) 0%, var(--pro-shimmer-gradient-color2) 20%, var(--pro-shimmer-gradient-color3) 40%, var(--pro-shimmer-gradient-color4) 100%);
    // animation: rotate-shimmer 2s infinite linear;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-name: rotate_shimmer;
    -webkit-animation-name: rotate_shimmer;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    }
    
    @keyframes rotate_shimmer {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
}
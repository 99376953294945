.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}

.table_popup {
  position: fixed;
  top: 70px;
  left: 50%;
  z-index: 2;
  // transform: translateX(-50%);
  background-color: #fff6db;
  border-radius: 3px;
  padding: 5px 10px;
  transform: scale(0.8) translateY(-10px);
  opacity: 0;
  transition: opacity 0.25s ease, transform 0.25s ease;
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.aling_btn {
  transform: rotate(90deg);
}

.avatar_container {
  position: relative;
  z-index: 3;

  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}
.new_coupon{
  font-size: 10px ;
  padding:1px 6px;
  display: inline-block;
}
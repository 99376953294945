.avatar_container {
  cursor: pointer;
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.box_root{
  position: relative;
  background-color: var(--pro-secondary);
  padding: 12px;
  border-radius: 4px;
  &+.box_root{
      margin-top: 12px;
  }
  .btn_close{
      display: block;margin-left: auto;
      width: 5px;height: 5px;
  }
}
.inner_row{
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  white-space: nowrap;
  min-height: 40px;
  span{
    max-width: 500px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:first-child{
    margin-top: -6px;
  }
  &:last-child{
    margin-bottom: -6px;
  }
  & + .inner_row{
    &::after{
      position: absolute;
      left: -20px;
      top: 0;
      width: calc(100% + 40px);
      background-color: var(--pro-outline-border);
      height: 1px;
      content: '';
    }
  }
  br{
    display: none;
  }
  p{
    margin-bottom: 0;
  }
}
.video_grid{
  position: relative;
  border-radius: 4px;
  overflow: hidden;
    &_list{
     position: relative;
     flex: 0 0 32.5%;
     
    }
    &_thumbnail{
      flex: 0 0 140px;
      border-top-left-radius: 4px ;
      border-bottom-left-radius: 4px;
      // min-height: 168px;
      // max-height: 168px;
      position: relative;
      overflow: hidden;
      
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &_dtls{
      position: relative;
      flex: 1;
      border: 1px solid var(--pro-outline-border);
      border-left: 0;
      border-top-right-radius: 4px ;
      border-bottom-right-radius: 4px;
     
      h6,p{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        margin-bottom: 8px;
      }
      p{
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
      }
      .watch_video_wrap{
        .time{
          font-size: 13px;
          font-weight: 500;
          line-height: 15.6px;
          letter-spacing: 0.01em;
          position: relative;
          padding-left: 10px;
          &::before{
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--pro-primary);
            top:0;
            left: 0;
            bottom: 0;
            margin: auto;
          }
        }
        button{
          span{
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 13px;
            
          }
          &:hover{
            svg *{
              fill:var(--pro-primary)
            }
          }
          
        }
      }
      .video_loader{
        background-color: #DFE3EB;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        overflow: hidden;

        span{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--pro-primary);
        }
      }
    }
    
  }
  
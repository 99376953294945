.dashboard_wrap {
  // padding: 0 28px;
  position: relative;
  :global{
    h1.pro-ttl{
      font-size: 30px;
    }
  }
  .dash_heading{
    margin: -16px -20px 0;
    :global{
      .nav{
        border: none;
        .nav-link{
          .pro-btn{
            transition: all .3s ease-in-out;
            &:focus,&:active,&:focus-visible{
              outline: none !important;
              box-shadow: none !important;
              border-color: var(--pro-outline-border);
            }
            &.active{
              color: var(--pro-dark);
              background-color: var(--pro-secondary);
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              &::after{
                display: none;
                background-color: var(--pro-primary);
              }
            }
          }
        }
      }
    }
    
  }
}

.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}
.table_popup{
  position: fixed;
  top: 70px;
  left: 50%;
  z-index: 2;
  // transform: translateX(-50%);
  background-color: #fff6db;
  border-radius: 3px;
  padding: 5px 10px;
  transform: scale(0.8) translateY(-10px);
  opacity: 0;
  transition: opacity 0.25s ease, transform 0.25s ease;
  pointer-events: none;
  &.visible{
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
.aling_btn{
  transform: rotate(90deg);
}

.avatar_container {
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.avatar_container {
  cursor: pointer;
  position: relative;
  z-index: 3;

  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.box_root {
  position: relative;
  background-color: var(--pro-secondary);
  padding: 12px;
  border-radius: 4px;

  .box_root_inner {
    margin-top: 12px;
    padding: 12px;
    position: relative;
    border-radius: 4px;
    border:1px solid var(--pro-outline-border);
  }
  .add_more_fields{
    position: relative;
  }
  .btn_close {
    display: block;
    margin-left: auto;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .add_more_btn{
    position: relative;
    top: -5px;
    cursor: pointer;
    :global{
      .material-symbols-outlined {
        margin-right: 3px;
        display: inline-block;
      }
    }
  }
}

.inner_row {
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  white-space: nowrap;
  min-height: 40px;

  span {
    max-width: 500px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-child {
    margin-top: -6px;
  }

  &:last-child {
    margin-bottom: -6px;
  }

  &+.inner_row {
    &::after {
      position: absolute;
      left: -20px;
      top: 0;
      width: calc(100% + 40px);
      background-color: var(--pro-outline-border);
      height: 1px;
      content: '';
    }
  }
}

@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";

.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: $primary-border-radius;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}

.pro_editor_wrapper {
  margin-bottom: 20px;
  position: relative;
  .pro_editor_main {
    padding: 10px;
    border: 1px solid var(--pro-outline-border);
    border-radius: 4px;
    max-height: 500px;
    height: auto;

    :global {
      .public-DraftStyleDefault-block {
        margin: 0;
      }

      .DraftEditor-root {
        min-height: 160px;
        max-height: 160px;
        max-height: 65vh;
        word-break: break-word;
        overflow-y: auto;

        .public-DraftStyleDefault-ul,
        .public-DraftStyleDefault-ol {
          margin: 0;

          &+* {
            margin-top: 12px;
          }
        }
      }
    }
  }

  .pro_editor_toolbar {
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    padding: 16px 5px;
    background-color: var(--pro-editor-toolbar-bg);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    row-gap: 10px;

    :global {

      .rdw-inline-wrapper,
      .rdw-list-wrapper,
      .rdw-link-wrapper {
        margin-bottom: 0;
        display: flex;
      }

      .rdw-block-wrapper {
        margin: 0 5px;
      }

      .rdw-option-wrapper {
        border: 0;
        background-color: transparent;
        transition: background-color;
        min-width: 20px;
        width: 20px;
        position: relative;
        box-shadow: none !important;
        border-radius: 50%;
        margin: 0 8px;
        --scale: 0.7;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          position: absolute;
          border-radius: 0;
          background-color: var(--pro-secondary-active);
          border-radius: 50%;
          content: "";
          height: 25px;
          width: 25px;
          left: 50%;
          top: 50%;
          transition: transform 0.2s ease-in, opacity 0.2s ease-in;
          transform: scale(var(--scale)) translate(-50%, -50%);
          opacity: 0;
          will-change: opacity, transform;
          z-index: -1;
        }

        &:hover::before,
        &.rdw-option-active::before {
          opacity: 1;
          --scale: 1;
        }

        // &:hover, &.rdw-option-active{
        //   background-color: var(--pro-secondary-active);
        // }
      }
    }

    .dropdown_wrapper {
      position: relative;
      background-color: var(--pro-light);
      min-width: 100px;

      &:hover {
        background-color: var(--pro-light);
        box-shadow: none;
      }

      >a {
        color: var(--pro-dark);
        font-weight: 500;
        width: 100%;

        :global(.rdw-dropdown-carettoopen) {
          border-top-color: var(--pro-dark);
        }

        :global(.rdw-dropdown-carettoclose) {
          border-bottom-color: var(--pro-dark);
        }
      }

      .dropdown_menu {
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 180px;
        background-color: var(--pro-light);
        border-color: var(--pro-outline-border);
        overflow-y: auto;
        z-index: 2;
        margin: 10px 0;

        &:hover {
          box-shadow: none;
        }

        li {
          min-height: auto;
          padding: 5px;
          font-size: 14px;
          cursor: pointer;
        }

        :global {
          .rdw-dropdownoption-active {
            background-color: var(--pro-primary);
            color: #ffffff; //no variables used bcause same color
          }

          .rdw-dropdownoption-highlighted:not(.rdw-dropdownoption-active) {
            background-color: var(--pro-input-option-hover-bg);
          }
        }
      }
    }
  }
}

[data-theme*="dark"] {
  .pro_editor_toolbar {
    :global(.rdw-option-wrapper img) {
      filter: invert(1);
    }
  }
}

.editor_container {
  display: flex;
  flex-direction: column;

  .error_text {
    color: var(--pro-danger);
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
  }
}
.contact_wrapper{
  p{
    margin-bottom: 15px;
  }
  ul{
    margin: 10px 0 15px;
    li{
      margin: 8px 0;
    }
  }
  .btn_edit{
    margin-left: 10px;
    padding: 0;
    box-shadow: none;
    border: none;
    background: transparent;
    display: flex;
    align-items: flex-end;
    transition: all .3s ease-in-out;
    &:hover{
      color: var(--pro-primary);
    }
  }
}

@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: $primary-border-radius;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}


.avatar_container {
  cursor: pointer;
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.box_root{
  position: relative;
  background-color: var(--pro-secondary);
  padding: 12px;
  border-radius: 4px;
  &+.box_root{
      margin-top: 12px;
  }
  .btn_close{
      display: block;margin-left: auto;
      width: 5px;height: 5px;
  }
}
.inner_row{
position: relative;
padding-top: 7px;
padding-bottom: 7px;
white-space: nowrap;
min-height: 40px;
span{
  max-width: 500px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
&:first-child{
  margin-top: -6px;
}
&:last-child{
  margin-bottom: -6px;
}
& + .inner_row{
  &::after{
    position: absolute;
    left: -20px;
    top: 0;
    width: calc(100% + 40px);
    background-color: var(--pro-outline-border);
    height: 1px;
    content: '';
  }
}
}

.add_more_wrap{
  position: relative;
  background-color: var(--pro-secondary);
  padding: 12px;
  border-radius: 4px;
  .box_root {
    position: relative;
    background-color: var(--pro-secondary);
    padding: 12px;
    border-radius: 4px;
    border:1px solid var(--pro-outline-border);
   
    .add_more_fields{
      position: relative;
    }
    .btn_close {
      display: block;
      margin-left: auto;
      width: 5px;
      height: 5px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .add_more_btn{
      position: relative;
      top: -5px;
      cursor: pointer;
      :global{
        .material-symbols-outlined {
          margin-right: 3px;
          display: inline-block;
        }
      }
    }
  }
}
.item_progress_container{
  .item_progress_bar{
    background-color: var(--pro-primary) !important;
  }
}
.list{
  &_root_wrap{
    position: relative;
  }
  &_root{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  &_item{
    color: #000 ;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    position: relative;
    border-radius: 12px;
    
    .agent_name{
      display: flex;
      align-items: center;
      column-gap: 10px;
      color: #000;
      img{
        border-radius: 50%;
      }
    }
    .count{
      flex: 0 0 50px;
      line-height: 1;
      text-align: center;
    }
  }
}

.bullet_list{
  display: flex;
  flex-wrap: wrap;
  // gap:10px;
  // @media (max-width: 1599px) {
  //   justify-content: space-between;
  // }
  .bullet_item{
    display: flex;
    font-size: 12px;
    font-weight: 500;
    // // max-width: 32%;
    // // width: 100%;
    // @media (max-width: 1599px) {
    //   max-width: 48%;
    // }
    
  }
  .bullet {
    width: 8px;
    height: 8px;
    flex: 0 0 8px;
    margin-right: 8px;
    display: inline-block;
    position: relative;
    top: 4px;
  }
}
.graph_box{
  display: flex;
  flex-direction: column;
  > div{
    flex: 1;
  }
  canvas{
    max-height: 250px;
    min-height: 250px;
  }
  &.line_graph{
    canvas{
      max-height: 350px;
      min-height: 350px;
    }
  }
}
.no_pad_box{
  display: flex;
  flex-direction: column;
  > div{
    flex: 1;
    padding: 0 !important;
  }
}
.table_box{
  > div{
    padding: 0 !important;
  }
  :global{
    .col-auto{
      padding: 0 !important;
      .pro-btn{
        margin: 10px 0 10px 16px;
      }
      div{
        padding: 0 !important;
        .pro-table{
          border: none !important;
        }
      }
    }
  }
}
.tab_btn{
  color: var(--pro-dark);
  font-weight: 400;
  cursor: pointer;
  position: relative;
  transition: all .3s ease-in-out;
  &:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -4px;
    right: 0;
    left: 0;
    margin: auto;
    height: 2px;
    background-color: var(--pro-primary);
    transition: all .3s ease-in-out;
  }
  &.active{
    // color:  var(--pro-primary);
    &:after {
      width: 100%;
    }
  }
}

// Light theme color variables
$pro-primary-light: #27B67C;
$pro-primary-text-light: #ffffff;
$pro-primary-hover-light: #5dbb94;
$pro-primary-active-light: #27B67C4D;
$pro-secondary-light: #f4f5f7;
$pro-secondary-hover-light: #f0f1f3;
$pro-secondary-active-light: #ebecf0;
$pro-success-light: #3FA11D;
$pro-success-bg-light: #3FA11D0D;
$pro-danger-light: #EF6677;
$pro-danger-bg-light: #EF66770D;
$pro-light-light: #fff;
$pro-dark-light: #000;
$pro-outline-light: #fff;
$pro-outline-hover-light: #f8f9fb;
$pro-outline-active-light: #f4f5f7;
$pro-outline-border-light: #dfe3eb;
$pro-disable-text-light: #a5adba;
$pro-disable-bg-light: #f5f6f7;
$pro-input-text-light: #4c4c4c;
$pro-input-hover-bg-light: #fafbfc;
$pro-input-focus-shadow-light: #12bf20;
$pro-input-active-light: #7a869a;
$pro-avatar-remaining-bg-light: #dfe1e6;
$pro-nav-btn-link-hover-light: #17bd2412;
$pro-check-icon-color-light: #a11d2b;
$pro-range-bg-light: #dbdee3;
$pro-image-gray-overlay-light: #ececec;
$pro-invoice-label-light: #676767;
$pro-input-option-hover-bg-light: #4caf5017;
$pro-input-option-active-bg-light: #ffe7f8;
$pro-editor-toolbar-bg-light: #fcfcfc;
$pro-offcanvas-bg-light: #ffffff;
$pro-pagination-active-color-light: #ffffff;
$pro-auth-page-bg-light: #f4f5f7;
$pro-dropdown-hover-bg-light: #f8f9fa;
$pro-table-link-color-light: #0a6812;
$pro-toggle-bg-light: #D2D1D4;
$pro-warning-light : #FCBB13;
$pro-warning-bg-light : #FCBB130D;
$pro-primary-active-light :#27B67C4D;
$pro-primary-border-light :#27B67C0D;

//shimmers
$pro-shimmer-bg-light: #f8f6f6;
$pro-shimmer-gradient-color1-light: #f6f7f8;
$pro-shimmer-gradient-color2-light: #edeef1;
$pro-shimmer-gradient-color3-light: #f6f7f8;
$pro-shimmer-gradient-color4-light: #f6f7f8;
$pro-blink-color-1-light: #f9f9f9;
$pro-blink-color-2-light: #f6f6f6;
$pro-blink-color-3-light: #f3f3f3;
$pro-blink-color-4-light: #f1f1f1;
$pro-blink-color-5-light: #e9e9e9;
$pro-blink-color-6-light: #e1e1e1;

// Dark theme color variables
$pro-primary-dark: #0C9B32;
$pro-primary-text-dark: #ffffff;
$pro-primary-hover-dark: #0fa737;
$pro-primary-active-dark: #0d8b2e;
$pro-secondary-dark: #4e4e4e;
$pro-secondary-hover-dark: #727272;
$pro-secondary-active-dark: #393939;
$pro-success-dark: #3FA11D;
$pro-success-bg-dark: #3FA11D0D;
$pro-danger-dark: #EF6677;
$pro-danger-bg-dark: #EF66770D;
$pro-light-dark: #1c1c1c;
$pro-dark-dark: #fff;
$pro-outline-dark: #000;
$pro-outline-hover-dark: #727272;
$pro-outline-active-dark: #393939;
$pro-outline-border-dark: #404040;
$pro-disable-text-dark: #a5adba;
$pro-disable-bg-dark: #f5f6f7;
$pro-input-text-dark: #ebeaea;
$pro-input-hover-bg-dark: #2a2a2a;
$pro-input-focus-shadow-dark: #c31899;
$pro-input-active-dark: #7a869a;
$pro-avatar-remaining-bg-dark: #dfe1e6;
$pro-nav-btn-link-hover-dark: #323232;
$pro-check-icon-color-dark: #a11d2b;
$pro-range-bg-dark: #dbdee3;
$pro-image-gray-overlay-dark: #ececec;
$pro-invoice-label-dark: #a7a7a7;
$pro-input-option-hover-bg-dark: #660047a6;
$pro-input-option-active-bg-dark: #660047a6;
$pro-editor-toolbar-bg-dark: #fcfcfc;
$pro-offcanvas-bg-dark: #000000;
$pro-pagination-active-color-dark: #ffffff;
$pro-auth-page-bg-dark: #000000;
$pro-dropdown-hover-bg-dark: #2e2e2e;
$pro-table-link-color-dark: #ffffff;
$pro-toggle-bg-dark: #D2D1D4;
$pro-warning-dark : #FCBB13;
$pro-warning-bg-dark : #FCBB130D;
$pro-primary-active-dark :#27B67C4D;
$pro-primary-border-dark :#27B67C0D;

//shimmers
$pro-shimmer-bg-dark: #3a3a3a;
$pro-shimmer-gradient-color1-dark: #3a3a3a;
$pro-shimmer-gradient-color2-dark: #4a4a4a;
$pro-shimmer-gradient-color3-dark: #3a3a3a;
$pro-shimmer-gradient-color4-dark: #3a3a3a;
$pro-nav-link-active-color-dark: #ffffff;
$pro-blink-color-1-dark: #000000;
$pro-blink-color-2-dark: #050505;
$pro-blink-color-3-dark: #0f0f0f;
$pro-blink-color-4-dark: #0f0f0f;
$pro-blink-color-5-dark: #1a1a1a;
$pro-blink-color-6-dark: #2e2e2e;

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 16px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}

.shimmer_container{
    table {
        width: 100%;
    
        tr {
             height: 25px;
             border-bottom: 1px solid rgb(223, 227, 235);
    
            td {
                  width: 60px;
                  padding:16px 12px;
                  &:first-child{
                       width: 14%;
                  }
                  .date_time{
                    :global{
                        .col-auto{
                            flex: 0 0 20px;
                        }
                    }
                  }
            }
        }
    }
}
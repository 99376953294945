.root {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    .rootIcon {
      display: flex;
      align-items: center;
    }
    &:empty {
      display: none;
    }
    >p{
      transition: color 0.3s ease-in;
    }
    &:hover >p{
      color: var(--pro-primary);
    }
  }

  .avatar_container {
    position: relative;
    z-index: 3;
    .img_wrap {
      max-width: 18px;
      min-width: 18px;
      flex: 1;
    }
  }
  
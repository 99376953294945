.color_picker {
  display: flex;
  height: 40px;
  position: relative;

  input{
    appearance: none;
    &:focus,&:focus-visible,&:active{
      outline: none !important;
      box-shadow: none !important;
      
    }
  }
  label{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block !important;
  }
  &.error{
    input{
      border-color:  var(--pro-danger);
    }
    
  }
  .icon{
    color: #fff;
    font-size: 23px !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;

  }
  .color_picked{
    padding: 0;
    flex: 0 0 40px;
    height: 100%;
    border:1px solid;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  .color_val {
    flex: 1;
    margin: 0;
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }
  .error_msg{
    position: absolute;
    bottom: -20px ;
    left: 0;
    font-size: 12px;
    line-height: 1;
    color: var(--pro-danger);
    display: inline-block;
  }
}



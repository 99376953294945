.range{
  &_slider{
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &_percentage{
    width: 100%;
    display: flex;
    justify-content: space-between;
    // position: relative;
    span{
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      display: inline-block;
      // min-width: 30px;
      &.current{
        position: absolute;
        top: -12px;
        left: 0;
        text-align: center;
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        em{
          font-style: normal;
        }
        &.hide{
          display: none;
        }
      }
      &.max{
        text-align: right;
      }
    }
  }
 
}
.root{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;;
    .line_chart_wrap{
        flex: 1 ;
        display: flex;
        align-items: flex-end;
        svg{
            height: auto !important;
        }
    }
    
    
}
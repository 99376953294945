.container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.moduleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
}

.module {
padding: 24px;
gap: 20px;
border-radius: 4px;
border: 1px solid #d4d4d4;

}

.moduleName {
 position: relative;
 span{
  font-size: 14px;
 }
}

.dayContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.dayContainerDisabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  opacity: 0.5;
}

.date {
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}
.day_grid{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 8px ;
}
.day_list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 79px;
    padding: 10px ;
    gap: 4px;
    border-radius: 18px ;
    border: 1px solid var(--pro-outline-border);
    background-color: #F5F5F5;
    opacity: 1;
    position: relative;
    text-align: center;
    &.disabled{
      opacity: 0.5;
    }
    &.rejected{
      border: 1px solid #FF725E;
      background-color: #FFF5F4;
    }
    .icon{
      position: absolute;
      bottom: -8px;
      left: -1px;
    }
    .day_month{
      font-size: 18px;
      font-weight: 700;
      line-height: 21.48px;
    }
    .day_week{
      font-size: 14px;
      font-weight: 400;
      line-height: 16.71px;
      letter-spacing: -0.022em;
    }
}

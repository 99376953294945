.announced_item{
    padding: 12px;
    position: relative;
    background: #F4F5F74D;
    border-bottom: 1px solid #F4F5F7;
    .icon{
        span{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #6842EF;
            background-color: #E6DFFF;
            font-size: 18px !important;
        }
    }
    .accounce_dtls{
        position: relative;
        color: #4C4C4C;
        .inner_wrap{
            max-width: 890px;
            width: 100%
        }
        .title{
            font-size: 14px;
            font-weight: 600;
            line-height: 19.6px;
            color: #171717;
        }
        .desc{
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        .date_time{
            font-size: 11px;
            font-weight: 500;
            line-height: 15.4px;
            :global{
                .pro-badge{
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 13.31px;
                }
            }
        }
        .created_date{
            position: absolute;
            top: 0;
            right: 30px;
            font-size: 13px;
            font-weight: 400;
            line-height: 18.2px;
            text-align: right;
        }
    }
}
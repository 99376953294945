.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}
.table_popup{
  position: fixed;
  top: 70px;
  left: 50%;
  z-index: 2;
  // transform: translateX(-50%);
  background-color: #fff6db;
  border-radius: 3px;
  padding: 5px 10px;
  transform: scale(0.8) translateY(-10px);
  opacity: 0;
  transition: opacity 0.25s ease, transform 0.25s ease;
  pointer-events: none;
  &.visible{
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
.aling_btn{
  transform: rotate(90deg);
}

.avatar_container {
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}
.otp_input_wrap{
  input{
    -pro-input-padding-x: 0.4375rem;
    --pro-input-padding-y: 0.34375rem;
    --pro-input-line-height: 1;
    --pro-input-with-icon-padding: 1.75rem;
    width: 100% !important;
    height: 32px;
    background-color: var(--pro-light);
    font-size: 0.875rem;
    line-height: var(--pro-input-line-height);
    padding: var(--pro-input-padding-y) var(--pro-input-padding-x);
    border: 1px solid var(--pro-outline-border);
    border-radius: 3px;
    color: var(--pro-input-text);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: 0.18s all ease-in-out;
    &:focus,&:active{
      outline: none;
      box-shadow: none;
    }
  }
  span{
    display: inline-block;
    font-size: 600;
    font-size: 18px;
  }
}

.main_wrapper {
  width: 100%;
  position: relative;
  padding-right: 24px;
  padding-left: 0;
}




.left_sidebar {
  width: 100%;
  max-width: 24.063rem;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  overflow: hidden;
  border-right: 1px solid var(--pro-outline-border);

  &_sticky {
    position: relative;

    .order_items_wrap {
      padding: 24px 0;

      .table td:first-of-type {
        font-size: 13px;
        font-weight: 500;
        width: 25%;
      }
    }

    .order_item {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 20px;
      cursor: pointer;

      .order_item_image {
        position: relative;
        width: 100%;
        max-width: 37.5px;
        box-shadow: 1px 1px 1px 0px #0000007d;
      }

      .item_description {
        line-height: 14px;
        max-width: 150px;
      }

      &:not(.active) {
        &+.order_item:not(.active) {
          border-top: 1px solid var(--pro-outline-border);
        }
      }

      &.active {
        background-color: #8E0E6D0D;
        border-block: 1px solid var(--pro-primary);

        &+.order_item.active {
          border-top: 0;
        }
      }
    }
  }

  .profile_layout_action {
    ul {
      padding: 0;

      li {
        display: flex;
        justify-content: center !important;
      }
    }
  }

  .profile_name_wrap :global(p) {
    margin-top: 5px;
  }

  .side_total_wrap {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    padding: 24px;
    background-color: var(--pro-light);
    box-shadow: 0px -3px 13px 0px rgba(0, 0, 0, 0.05);
  }
}

.main_container {
  width: 100%;
  max-width: calc(100% - 385px);
  transition: 0.3s max-width ease-in-out;
  flex: 1;
  padding-top: 24px;
  padding-left: 24px;

   :global {
    // .nav {
    //   @media(max-width : 1399px) {
    //     overflow: auto;
    //     overflow-y: hidden;
    //   }

    //   .nav-link {
    //     white-space: pre;

    //     .pro-btn-link {
    //       --pro-button-hover-bg: var(--pro-primary-hover-bg);
    //       transition: none;

    //       &.active {
    //         color: var(--pro-primary);
    //         border-top-left-radius: 6px;
    //         border-top-right-radius: 7px;

    //         &::after {
    //           background-color: var(--pro-primary);
    //         }
    //       }

    //       &:not(.active):active {
    //         background-color: var(--pro-button-hover-bg);
    //       }
    //     }
    //   }
    // }

    .pro-pagination-wrapper {
      width: calc(100% - 385px);
    }
  }
}
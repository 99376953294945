.main_policy {
     width: 100%;
     display: flex;
     gap: 20px;

     .main_policy_half {
          width: 100%;
          padding: 12px;


          .admin_content_area {

               ol,
               ul {
                    margin-bottom: 1rem;
                    padding-left: 1.5rem;

                    li {
                         list-style: inherit;

                         &:not(:last-of-type) {
                              margin-bottom: 0.5rem;
                         }
                    }
               }
          }
     }
}





.modal_root {
     width: 100%;
     --pro-modal-width: 1395px;
     // --pro-modal-margin: 0;
     --pro-modal-border-width: 0;

     :global {
          .modal_content_wrap {
               position: relative;
               min-height: 400px;

               .form-preloader-wrap {
                    height: 90%;
               }
          }
     }
}
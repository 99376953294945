@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/bootstrap.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/common.scss";
@import "swiper/swiper.min.css";
@import "./config";

:root,
[data-theme*="light"] {
  @include light-theme;
  --pro-range-bg: #{$pro-range-bg-light};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-light};
  --pro-invoice-label: #{$pro-invoice-label-light};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-light};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-light};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-light};
  --pro-editor-toolbar-bg: #{$pro-editor-toolbar-bg-light};
  --pro-shimmer-bg: #{$pro-shimmer-bg-light};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-light};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-light};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-light};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-light};
  --pro-offcanvas-bg-color: #{$pro-offcanvas-bg-light};
  --pro-pagination-active-color: #{$pro-pagination-active-color-light};
  --pro-auth-page-bg: #{$pro-auth-page-bg-light};
  --pro-blink-color-1: #{$pro-blink-color-1-light};
  --pro-blink-color-2: #{$pro-blink-color-2-light};
  --pro-blink-color-3: #{$pro-blink-color-3-light};
  --pro-blink-color-4: #{$pro-blink-color-4-light};
  --pro-blink-color-5: #{$pro-blink-color-5-light};
  --pro-blink-color-6: #{$pro-blink-color-6-light};
  --pro-dropdown-hover-bg: #{$pro-dropdown-hover-bg-light};
  --pro-table-link-color: #{$pro-table-link-color-light};
  --pro-toggle-bg: #{$pro-toggle-bg-light};
  --pro-warning : #{$pro-warning-light};
  --pro-warning-light : #{$pro-warning-bg-light};
  --pro-success-light : #{$pro-success-bg-light};
  --pro-danger-light : #{$pro-danger-bg-light};
  --pro-primary-light-active : #{$pro-primary-active-light};
  --pro-primary-light-border : #{$pro-primary-border-light};
}

[data-theme*="dark"] {
  @include dark-theme;
  --pro-range-bg: #{$pro-range-bg-dark};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-dark};
  --pro-invoice-label: #{$pro-invoice-label-dark};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-dark};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-dark};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-dark};
  --pro-editor-toolbar-bg: #{$pro-editor-toolbar-bg-dark};
  --pro-shimmer-bg: #{$pro-shimmer-bg-dark};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-dark};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-dark};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-dark};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-dark};
  --pro-offcanvas-bg-color: #{$pro-offcanvas-bg-dark};
  --pro-pagination-active-color: #{$pro-pagination-active-color-dark};
  --pro-auth-page-bg: #{$pro-auth-page-bg-dark};
  --pro-blink-color-1: #{$pro-blink-color-1-dark};
  --pro-blink-color-2: #{$pro-blink-color-2-dark};
  --pro-blink-color-3: #{$pro-blink-color-3-dark};
  --pro-blink-color-4: #{$pro-blink-color-4-dark};
  --pro-blink-color-5: #{$pro-blink-color-5-dark};
  --pro-blink-color-6: #{$pro-blink-color-6-dark};
  --pro-dropdown-hover-bg: #{$pro-dropdown-hover-bg-dark};
  --pro-table-link-color: #{$pro-table-link-color-dark};
  --pro-toggle-bg: #{$pro-toggle-bg-dark};
  --pro-warning : #{$pro-warning-dark};
  --pro-warning-light : #{$pro-warning-bg-dark};
  --pro-success-light : #{$pro-success-bg-dark};
  --pro-danger-light : #{$pro-danger-bg-dark};
  --pro-primary-light-active : #{$pro-primary-active-dark};
  --pro-primary-light-border : #{$pro-primary-border-dark};
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: var(--pro-input-focus-shadow);
    background-color: #e2e2e2;
  }

  scrollbar-width: thin;
}

body {
  font-size: $font-size-normal;
  line-height: $primary-line-height;
  --toastify-toast-min-height: 56px;
  --toastify-z-index: 999;
}

body,
button,
input,
select,
textarea {
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input[type="submit"],
.button,
.pro-btn-link {
  display: inline-block;
  cursor: pointer;
  transition: 0.18s color ease-in-out, 0.18s background-color ease-in-out, 0.18s opacity ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

textarea {
  height: 100px;

  &.pro-input {
    height: 100px !important;
  }
}

.pro-toast-container {
  top: 60px !important;

  .pro-toast-progress {
    height: 2px;
  }
}

.wrapper {
  width: max-content;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;

  >div {
    height: 100%;
  }
}

.wrapper-table {
  flex: 1;
  width: calc(100% - 260px);
  overflow: hidden;

  .pro-pagination {
    display: flex;
    justify-content: center;
  }
}

.wrapper-tables {
  width: 100%;
  overflow: hidden;
}

.pt-custom {
  padding-top: size(57);
}

.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}

.scoll-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--pro-primary);
  background-color: var(--pro-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  z-index: 999;
  transition: 0.3s all ease-in-out;

  span {
    font-size: 25px !important;
    color: var(--pro-primary);
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    background-color: var(--pro-primary);

    span {
      color: var(--pro-light) !important;
    }
  }
}

.pro-btn {
  &.pro-btn-outline-primary {
    --pro-button-color: var(--pro-primary);
    --pro-button-hover-color: var(--pro-light);
    border: 1px solid var(--pro-primary);
    --pro-button-hover-bg: var(--pro-primary);
    --pro-button-padding-y: #{size(8)};

    &:active,
    &:hover {
      opacity: 1 !important;
    }

  }

  &.pro-btn-primary {
    --pro-button-color: var(--pro-light);
    --pro-button-bg: var(--pro-primary);
    border: 1px solid var(--pro-primary);
    --pro-button-hover-color: var(--pro-primary);
    --pro-button-hover-bg: var(--pro-light);

    &:active,
    &:hover {
      opacity: 1 !important;

    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.app-loading {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  &::after {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pro-primary);
    border-radius: 50%;
    overflow: hidden;
    border-bottom-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 0.6s infinite linear;
  }

  &.fill {
    position: fixed;
  }
}

@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.pro-pnt {
  color: var(--pro-table-link-color);
  font-weight: 600; // no variables for fw-semibold
  cursor: pointer;
}

.shimmer {
  background: var(--pro-shimmer-bg);
  background-image: linear-gradient(to right,
      var(--pro-shimmer-gradient-color1) 0%,
      var(--pro-shimmer-gradient-color2) 20%,
      var(--pro-shimmer-gradient-color3) 40%,
      var(--pro-shimmer-gradient-color4) 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: shimmer;
  -webkit-animation-name: shimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;

  &.letter {
    width: 100%;
    height: 15px;

    &.sub {
      width: 90%;
    }

    &.para {
      height: 6px;
    }
  }

  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.blink {
  background-color: var(--pro-blink-color-1);
  display: inline-block;
  position: relative;
  animation-name: blink;
  -webkit-animation-name: blink;
  animation-duration: var(--blink-duration);
  -webkit-animation-duration: var(--blink-duration);
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-delay: var(--blink-delay);
  -webkit-animation-delay: var(--blink-delay);

  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@keyframes blink {
  0% {
    background-color: var(--pro-blink-color-1);
  }

  10% {
    background-color: var(--pro-blink-color-2);
  }

  20% {
    background-color: var(--pro-blink-color-3);
  }

  30% {
    background-color: var(--pro-blink-color-4);
  }

  40% {
    background-color: var9--pro-blink-color-5;
  }

  50% {
    background-color: var(--pro-blink-color-6);
  }

  60% {
    background-color: var9--pro-blink-color-5;
  }

  70% {
    background-color: var(--pro-blink-color-4);
  }

  80% {
    background-color: var(--pro-blink-color-3);
  }

  90% {
    background-color: var(--pro-blink-color-2);
  }

  100% {
    background-color: var(--pro-blink-color-1);
  }
}

.input-country-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  .input-main-wrap {
    position: relative;
    width: 100%;

    .code-wrap {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.34375rem 0.4375rem;
      font-size: 0.85rem;
      max-width: 84px;
      cursor: pointer;
      user-select: none;

      &::after {
        position: absolute;
        content: "";
        width: 1px;
        top: 5px;
        bottom: 5px;
        right: 0;
        background-color: var(--pro-border-color);
      }

      .country-select {
        .pro-input__control {
          background-color: inherit;

          .pro-input__single-value {
            color: var(--pro-dark);
          }
        }

        .pro-input__menu {
          background-color: var(--pro-light);
        }
      }

      .pro-input__control {
        border: 0;
        box-shadow: none !important;
      }

      .pro-input__value-container {
        padding: 0;
      }

      .pro-input__indicator-separator {
        display: none;
      }

      .pro-input__placeholder {
        font-size: 14px;
        color: var(--pro-dark);
      }

      .pro-input__indicator {
        padding: 4px;
      }

      .pro-input__menu-list {
        max-height: 250px;
      }

      .pro-input__menu {
        width: max-content;
        max-width: 300px;
      }
    }

    .pro-input {
      padding-left: 6rem;
    }

    &.verify-btn-wrap .pro-input {
      padding-right: 5rem;
    }
  }

  .error-text {
    font-size: 12px;
    line-height: $secondary-line-height;
    margin-top: 10px;
    color: var(--pro-danger);
  }
}

.verify-btn-wrap {
  position: relative;

  >.pro-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
  }
}

.offcanvas-footer-sticky-btns {
  position: sticky;
  bottom: -20px;
  padding: 16px 0;
  margin-bottom: -20px;
  background-color: var(--pro-offcanvas-bg-color);
  z-index: 8;
}

.pro-input__option {
  &:not(.pro-input__option--is-selected).pro-input__option--is-focused {
    background-color: var(--pro-input-option-hover-bg);
  }

  &:active {
    background-color: var(--pro-input-option-active-bg);
  }

  &.pro-input__option--is-selected {
    background-color: var(--pro-primary) !important;
    color: var(--pro-pagination-active-color) !important;
  }
}

.pro-input__control.css-13cymwt-control {
  cursor: pointer;
}

.hide-icon-input {
  padding-right: 40px;
}

.p-re {
  position: relative;

  .input-wrap {
    position: relative;
  }

  .hide-icon {
    right: 0;
  }
}

.hide-icon {
  position: absolute;
  right: 0;
  top: 24px;
  border: none;
  background-color: transparent;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--pro-dark);
}

.modal .pro-btn-link .material-symbols-outlined {
  font-size: 1.5rem !important;
}

.dropdown .dropdown-item {
  transition: all 0.3s ease-in;
}

.pro-table {

  th,
  td {
    max-width: 400px;
    word-break: break-word;
    min-width: 150px;

    &.checkbox-cell {
      min-width: 44px;
    }
  }

  td {
    .pro-btn-link {
      margin-left: 5px;
    }
  }

}

.no-border {
  .pro-input__control {
    border: none !important;
    cursor: pointer;
    background-color: inherit;
    box-shadow: none;

    .pro-input__single-value {
      color: var(--pro-dark);
    }

    &.pro-input__control--menu-is-open {
      box-shadow: 0 0 0 1px var(--pro-primary);
    }

    .pro-input__input-container {
      color: var(--pro-dark);
    }
  }

  .pro-input__menu {
    background-color: var(--pro-light);
  }

  .pro-input__indicators .pro-input__indicator-separator {
    display: none;
  }
}

.grey_bg {
  background-color: var(--pro-secondary);
}

.offcanvas.offcanvas-end {
  background-color: var(--pro-offcanvas-bg-color);
}

.pro-input:-webkit-autofill,
.pro-input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--pro-dark);
}

.input-date-picker {
  width: 100%;
  height: 40px;

  >div {
    width: 100%;

    .MuiInputBase-formControl {
      .MuiOutlinedInput-input {
        padding: 0;
        padding-left: 10px;
      }

      fieldset {
        border: none;
      }
    }
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--pro-dark);
  background-color: var(--pro-dropdown-hover-bg);
}

.dashboard_no_padding {
  padding-top: 0 !important;
}

.scroll-table-wrap {
  display: flex;

  overflow-x: auto;
}

.pro-table-wrap {
  white-space: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  .pro-table {
    border-left: 0;
    height: 100%;
  }

  &+.pro-table-wrap {
    border-left: 1px solid var(--pro-outline-border);
  }

  &.fixed-table {
    max-width: size(550);
  }

  tr {

    th,
    td {
      &:nth-child(-n+3) {
        z-index: 3;
        position: sticky;
      }

      &:first-child {
        left: 0;
        min-width: 44px;
        max-width: 44px;
        border-left: 1px solid var(--pro-outline-border);
      }

      &:nth-child(2) {
        left: 44px;
        min-width: 275px;
        max-width: 275px;
      }

      &:nth-child(3) {
        left: calc(44px + 275px);
        min-width: 275px;
        max-width: 275px;
      }
    }

    td:nth-child(-n+3) {
      background-color: var(--pro-light);
    }
  }

  >div {
    position: sticky;
    left: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pro-pagination-wrapper {
  z-index: 3;
}

.pro-no-point {
  pointer-events: none;
}

.search-filter-select {
  min-width: 200px;
}

.no-wrap {
  white-space: nowrap;
}

.dropdown-filter .pro-input .pro-input__single-value {
  padding: 0;
}

.pro-toggle {
  display: flex;
  align-items: center;

  .pro-toggle-box {
    position: relative;
    width: 28px;
    height: 16px;

    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      appearance: none;
      background: var(--pro-toggle-bg);
      border-radius: 100px;
      cursor: pointer;
      transition: 0.4s;
    }

    input:checked[type="checkbox"] {
      background: var(--pro-primary);
    }

    input[type="checkbox"]~span {
      position: absolute;
      content: "";
      width: 13px;
      height: 13px;
      top: 50%;
      left: 1px;
      background: var(--pro-light);
      border-radius: 50%;
      transition: 0.3s;
      pointer-events: none;
      transform: translateY(-50%);
    }

    input:checked[type="checkbox"]~span {
      left: 49%;
    }

    &+label {
      margin-left: 12px;
      color: var(--pro-input-text);
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

.pro-opacity-50 {
  opacity: 0.5;
}

.pro-h-100 {
  height: 100%;
}

.pro-border-top {
  border-top: 1px solid var(--pro-outline-border);
}

.pro-border-bottom {
  border-bottom: 1px solid var(--pro-outline-border);
}

.pro-bg-light {
  background-color: var(--pro-light) !important;
}

.pro-text-success {
  color: var(--pro-primary);
}

// .pro-back-btn {
//   span {
//     text-decoration: underline;
//     text-underline-offset: 3px;
//   }
// }

.pro-badge {
  &.badge-success {
    --wac-badge-bg: rgba(39, 182, 124, 0.1);
    --wac-badge-color: #27B67C !important;
    --wac-badge-border-color: rgba(39, 182, 124, 0.1);
  }
}

// .left-sidebar {

// .pro-back-btn {
//   border: none;
//   background: transparent;
//   box-shadow: none;
//   padding: 0;
//   color: #000;
//   transition: all .3s ease-in;

//   &:focus {
//     outline: none;
//   }

//   &:hover {
//     font-weight: 600;
//   }

//   span {
//     display: inline-block;
//     line-height: 1;

//     &.title {
//       text-decoration: underline;
//       font-size: 14px;
//       font-weight: 500;
//     }
//   }
// }
// }

.upload-wrap {
  position: relative;

  .stretched-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.input-wrap {
  .input-drag {
    &.active {
      .input-drag-box {
        border: 1px solid var(--pro-primary-light-active);
        background-color: var(--pro-primary-light-border);

        .image-root {
          max-width: 86px;
          width: 100%;
          max-height: 44px;

          img {
            max-width: 100%;
            height: auto;
            border-radius: 6px;
          }
        }
      }
    }

    .img-wrap {
      width: max-content;
      position: relative;

      .img-close {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-size: 6px;
        font-weight: 300;
        display: block;
        line-height: 12px;
        color: #000;
        background-color: #fff;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
        margin: 0;
        box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
      }

      figure {
        padding-bottom: 0 !important;

        img {
          position: relative !important;
          max-height: 44px;
        }
      }
    }
  }
}

.video-upload-wrap {
  min-height: 60px;

  &.active {
    border: 1px solid var(--pro-primary-light-active);
    background-color: var(--pro-primary-light-border);
    box-shadow: none;
  }

  .input-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .pro-input {
      border: none;
      border-radius: 2px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
    }

    .file-name {
      font-weight: 600;
      font-size: 14px;
    }

    .btn-close {
      font-size: 10px;
    }
  }
}

.rdw-editor-toolbar {
  flex-wrap: wrap;

  .rdw-text-align-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .rdw-dropdown-wrapper {
    position: relative;
    margin-right: 10px;

    .rdw-dropdown-selectedtext {
      background-color: #fff;
      padding: 7px 10px;
      border-radius: 4px;
      color: #000;
      display: block;
    }

    ul {
      position: absolute;
      top: 98%;
      left: 0;
      width: 100%;
      background-color: #e2e2e2;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      min-width: max-content;
      z-index: 1;

      li {
        list-style-type: none;
        margin: 0;
        cursor: pointer;
        padding: 5px 10px;
        color: #000;
        display: block;
        transition: all .3s ease-in-out;
      }
    }
  }
}

.video-modal {
  .modal-dialog {
    max-width: 1000px !important;
    width: 100%;
  }

  .video-wrap {
    position: relative;
    background-color: #000;

    &::after {
      content: '';
      display: block;
      height: 0;
      width: 100%;
      padding-top: 56.25%;
    }

    video,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // if video modal heading is not needed uncomment this style 
  // .modal-dialog {
  //   transform: none;
  //   height: 100vh;
  //   display: flex;
  //   overflow: hidden;
  //   align-items: center;
  //   justify-content: center;
  //   margin: 0 auto;
  // }
  // .modal-content{
  //   background-color: transparent;
  //   border: none;
  //   .pro-ttl{
  //     display: none;
  //     & + button{
  //       position: absolute;
  //       top: -23px;
  //       right: 0;
  //       z-index: 1;
  //     }
  //   }
  //   > div,.inner{
  //     padding: 0 !important;
  //   }

  //   .close{
  //     color: #fff;
  //   }
  //   .video-wrap{
  //     padding: 0 !important;
  //   }
  // }
}

.input-wrap {
  position: relative;

  input[type='range'] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    position: relative;
    border-radius: 8px;
    background: linear-gradient(to right, var(--pro-primary) 0%, #DFE3EB 0%);
    /* Initial gradient */

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      border-radius: 25px;
      border: 0px solid #000101;
      background: transparent;
      /* Ensure it is transparent to show the dynamic background */
    }

    &::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 1px solid #fff;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--pro-primary);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -5.5px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      border-radius: 25px;
      border: 0px solid #000101;
      background: transparent;
      /* Ensure it is transparent to show the dynamic background */
    }

    &::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 1px solid #fff;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--pro-primary);
      cursor: pointer;
    }

    &::-ms-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 20px 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: var(--pro-primary);
      border-radius: 50px;
      box-shadow: 0px 0px 0px #000000;
    }

    &::-ms-fill-upper {
      background: grey;
      border-radius: 50px;
      box-shadow: 0px 0px 0px #000000;
    }

    &::-ms-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 1px solid #fff;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--pro-primary);
      cursor: pointer;
    }
  }

  .error-text {
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
    color: var(--pro-danger);
  }

  input.error {
    border: 1px solid var(--pro-danger);
  }
}

.otp-input-wrap {
  input {
    padding: 0.34375rem 0.4375rem;
    line-height: 1;
    width: 100% !important;
    background-color: var(--pro-light);
    font-size: 0.875rem;
    border: 1px solid var(--pro-outline-border);
    border-radius: 3px;
    color: var(--pro-input-text);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: 0.18s all ease-in-out;
  }

  span {
    padding: 0 8px;
    font-weight: 700;
    display: inline-block
  }

  &.error {
    input {
      border: 1px solid var(--pro-danger) !important;
    }
  }
}

.item_dtls_wrap {
  position: relative;

  .icon {

    &_edit {
      position: absolute;
      top: 0;
      right: 0;
      color: #000;
      font-size: 18px !important;
    }
  }
}

.multi_column_table_item_details {

  .pro-table {
    tr {
      margin-bottom: 12px;

      td {

        &:first-child {
          font-size: 13px;
          font-weight: 500;
          padding-right: 15px;
          width: 40%;

          p {
            font-size: 13px;
            font-weight: 500;
          }
        }

        &:last-child {
          padding-left: 15px;
          font-size: 14px;
          font-weight: 400;
          width: 60%;

          p {
            font-size: 14px;
            font-weight: 400;
            word-break: break-word;
          }
        }
      }
    }

  }
}

// .multi_col_tb{
//   tr{
//     td{

//       &:first-child{
//         font-size: 13px;
//         font-weight: 500;
//         p{
//           font-size: 13px;
//           font-weight: 500;
//         }
//       }
//       &:last-child{
//         font-size: 14px;
//         font-weight: 400;
//         p{
//           font-size: 14px;
//           font-weight: 400;
//           word-break: break-word;
//         }
//       }
//     }
//   }

// }
.tab_wrapper {
  width: 100%;
  position: relative;

  .nav {
    position: sticky;
    top: 57px;
    background-color: var(--pro-light);
    z-index: 9;

    // border-bottom: 0; //removed the border of nav
    .nav-link {
      white-space: pre;
      bottom: -1px;

      .pro-btn-link {
        --pro-button-hover-bg: var(--pro-primary-hover-bg);
        transition: none;

        &.active {
          color: var(--pro-primary);
          border-top-left-radius: 6px;
          border-top-right-radius: 7px;

          &::after {
            background-color: var(--pro-primary);
          }
        }

        &:not(.active):active {
          background-color: var(--pro-button-hover-bg);
        }
      }
    }
  }

  .col-auto {
    &.pro-ps-5 {
      padding-left: 0 !important;
    }
  }
}

.profile-modal {
  .pro-ttl {
    text-align: center;
    width: 100%;
  }
}

.row.box-heading {
  margin-top: -16px;
  margin-left: -16px;
  margin-right: -16px;
  min-height: 52px;
  margin-bottom: 20px;

  .col {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.pro-badge {
  --wac-badge-bg: #f4f5f7 !important;
  --wac-badge-color: #000 !important;
  --wac-badge-border-color: #F0EEFD !important;

  &.badge-warning-outline {
    --wac-badge-bg: transparent !important;
    --wac-badge-color: var(--pro-warning) !important;
    --wac-badge-border-color: var(--pro-warning) !important;
  }

  &.badge-warning {
    --wac-badge-bg: var(--pro-warning-light) !important;
    --wac-badge-color: var(--pro-warning) !important;
    --wac-badge-border-color: var(--pro-warning-light) !important;
  }

  &.badge-danger-outline {
    --wac-badge-bg: transparent !important;
    --wac-badge-color: var(--pro-danger) !important;
    --wac-badge-border-color: var(--pro-danger) !important;
  }

  &.badge-danger {
    --wac-badge-bg: var(--pro-danger-light) !important;
    --wac-badge-color: var(--pro-danger) !important;
    --wac-badge-border-color: var(--pro-danger-light) !important;
  }

  &.badge-pending-outline {
    --wac-badge-bg: #8b8b8b20 !important;
    --wac-badge-color: #8b8b8b !important;
    --wac-badge-border-color: #8b8b8b !important;
  }

  &.badge-success-outline {
    --wac-badge-bg: transparent !important;
    --wac-badge-color: var(--pro-success) !important;
    --wac-badge-border-color: var(--pro-success) !important;
  }

  &.badge-success {
    --wac-badge-bg: var(--pro-success-light) !important;
    --wac-badge-color: var(--pro-success) !important;
    --wac-badge-border-color: var(--pro-success-light) !important;
  }

  &.badge-info {
    --wac-badge-bg: #007BFF0D !important;
    --wac-badge-color: #007BFF !important;
    --wac-badge-border-color: #007BFF0D !important;
  }

  &.badge-info-outline {
    --wac-badge-bg: transparent !important;
    --wac-badge-color: #007BFF !important;
    --wac-badge-border-color: #007BFF !important;
  }

  &.badge-primary-light {
    --wac-badge-bg: #f4f5f7;
    --wac-badge-color: #000000;
    --wac-badge-border-color: #f4f5f7;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 16px;
  }

  &.badge-success {
    --wac-badge-bg: var(--pro-success);
    --wac-badge-color: var(--pro-light);
    --wac-badge-border-color: var(--pro-success);
  }
}

.pro-w {
  &-10 {
    width: 10% !important;
  }

  &-25 {
    width: 25% !important;
  }

  &-50 {
    width: 50% !important;
  }

  &-75 {
    width: 75% !important;
  }
}

.pro-border {
  border: 1px solid var(--pro-outline-border);

  &-left {
    border-left: 1px solid var(--pro-outline-border);
  }

  &-right {
    border-right: 1px solid var(--pro-outline-border);
  }
}

input[type="date"].pro-input {
  color: #808080;

  &.date-selected {
    color: #000;
  }
}

.table_scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  table {
    width: 100%;

  }
}

.dash_scroll_wrap {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 100001;

  &_info {
    visibility: hidden;
    width: max-content;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    font-size: 12px;
    bottom: 125%; // Position the tooltip above the icon
    left: 50%;

    // margin-left: -60px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }

  &:hover &_info {
    visibility: visible;
    opacity: 1;
  }
}

.rs-picker-menu {
  left: inherit !important;
  right: 18px;

  .rs-picker-daterange-calendar-group {
    display: flex;
  }
}

.main_heading {
  .pro-ttl {
    line-height: 1;
  }
}

.material-symbols-outlined {
  font-size: 18px !important;
}

.multi_col_tb {
  .pro-ttl {
    font-weight: 600;
  }
}

.pro-container {
  padding-left: 20px;
  padding-right: 20px;
}

.has-scrollbar {
  padding-right: 10px !important;
}

.pro-back-btn {
  display: inline-flex;
  align-items: center;
  color: var(--pro-dark);
  text-decoration: underline;
  padding-left: 20px;
  position: relative;
  font-weight: 500;
  text-underline-offset: 2px;

  span {
    position: absolute;
    left: 0;
    font-weight: 700;
    transition: transform 0.25s;
    will-change: transform;
  }

  &:hover span {
    transform: translateX(-3px);
  }
}
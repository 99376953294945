.image{
    width: 32px;
    height: 32px;
}
.text{width: 50%;}
.root{
    position: relative;
    // & +.root{
    //     padding-top: 1rem;
    //     margin-top: 1rem;
    //     border-top: 1px solid var(--pro-border-color);
    // }
}